import * as React from "react"
import Main from "../components/Layout/Main";
import "../styles/pages/sobre-nos.scss"
import Breadcrumb from "../components/Layout/Breadcrumb";
import images from "../theme/images";
import Images from "../theme/images";
import DownloadPdf from "../components/DownloadPdf";
import { Swiper, SwiperSlide } from "swiper/react";
import aboutUs from "../theme/aboutUs"
import Image from "../components/Image";

// Import Swiper styles
import "swiper/css";
import "swiper/css/lazy"
import "swiper/css/pagination"
import "swiper/css/navigation"

// import Swiper core and required modules
import SwiperCore, {
    Lazy, Pagination, Navigation
} from 'swiper';
import Seo from "../components/Seo";

// install Swiper modules
SwiperCore.use([Lazy, Pagination, Navigation]);


// install Swiper modules
SwiperCore.use([Pagination]);

const SobreNosPage = () => {
    return (
        <Main>
            <Seo title="Nossa História" description="Tudo começou com um sonho de levar diversão, aprendizado lúdico e trazer de volta o eterno espírito da brincadeira que existe em cada um de nós, não importa a idade."/>
            <Breadcrumb title="Nossa História"/>
            <div className="container mx-auto px-4 mb-32">
                <div className="mt-28 xl:mx-32">
                    <div className="flex steps">
                        <div className="w-40 xl:w-80 text-right step-year relative">
                            <p className="text-3xl md:text-5xl font-bold pr-8 step-color">2008</p>
                            <span className="text-xl xl:text-2xl font-bold text-dark-gray pr-8">Ideia</span>
                        </div>
                        <div className="flex-1 ml-16 pb-10">
                            <span className="text-l md:text-2xl xl:text-3xl font-bold step-color mt-3.5 mb-2 block break-all">Onde tudo começou</span>
                            <img loading="lazy" src={images.logo} className="h-12 float-left" alt="Mister Brinque"/>
                            <p className="text-dark-gray text-justify">
                                A <span className="font-bold">Mister Brinque</span> é uma empresa localizada em Porto Feliz, no interior de São Paulo.
                            </p>
                            <p className="text-dark-gray text-justify">E tudo começou com um sonho de levar diversão, aprendizado lúdico e trazer de volta o eterno espírito da brincadeira que existe em cada um de nós, não importa a idade.</p>
                        </div>
                    </div>
                    <div className="flex steps">
                        <div className="w-40 xl:w-80 text-right step-year relative">
                            <p className="text-3xl md:text-5xl font-bold pr-8 step-color">2013</p>
                            <span className="text-xl xl:text-2xl font-bold text-dark-gray pr-8">Iniciativa</span>
                        </div>
                        <div className="flex-1 ml-16 pb-10">
                            <span className="text-l md:text-2xl xl:text-3xl font-bold step-color mt-3.5 mb-2 block break-all">Início da criação de novas linhas de brinquedos</span>
                            <p className="text-dark-gray text-justify">Tudo pautado pela melhoria contínua dos processos de criação dos produtos, pela qualidade e segurança constantes e pelos compromissos de responsabilidade social e ambiental.</p>
                        </div>
                    </div>
                    <div className="flex steps">
                        <div className="w-40 xl:w-80 text-right step-year relative">
                            <p className="text-3xl md:text-5xl font-bold pr-8 step-color">2015</p>
                            <span className="text-xl xl:text-2xl font-bold text-dark-gray pr-8">1ª Ampliação</span>
                        </div>
                        <div className="flex-1 ml-16 pb-10">
                            <span className="text-l md:text-2xl xl:text-3xl font-bold step-color mt-3.5 mb-2 block break-all">Departamentalização e áreas específicas</span>
                            <p className="text-dark-gray text-justify">Assim, com muito estudo, conquistamos uma posição de destaque no, cada vez mais exigente, universo dos brinquedos.</p>
                        </div>
                    </div>
                    <div className="flex steps">
                        <div className="w-40 xl:w-80 text-right step-year relative">
                            <p className="text-3xl md:text-5xl font-bold pr-8 step-color">2020</p>
                            <span className="md:text-xl xl:text-2xl font-bold text-dark-gray pr-8">Mister Brinque</span>
                        </div>
                        <div className="flex-1 ml-16 pb-10">
                            <span className="text-l md:text-2xl xl:text-3xl font-bold step-color mt-3.5 mb-2 block float-left">Nascimento da nova marca <img loading="lazy" src={images.logo} className="h-28 lg:float-right lg:-mt-10" alt="Mister Brinque"/></span>
                            <div className="clear-both"></div>
                            <p className="text-dark-gray text-justify">Com representantes espalhados por todo o Brasil, os produtos Mister Brinque estão presentes em milhares de lares. E para cada representante, investir na Mister Brinque é sinônimo de investir em qualidade.</p>
                        </div>
                    </div>
                    <div className="flex steps">
                        <div className="w-40 xl:w-80 text-right step-year relative">
                            <p className="text-3xl md:text-5xl font-bold pr-8 step-color">2021</p>
                            <span className="text-xl xl:text-2xl font-bold text-dark-gray pr-8">2ª Ampliação</span>
                        </div>
                        <div className="flex-1 ml-16 pb-10">
                            <span className="text-l md:text-2xl xl:text-3xl font-bold step-color mt-3.5 mb-2 block break-all">ShowRoom + ampliação da fábrica</span>
                            <p className="text-dark-gray text-justify">Acreditamos no sorriso mais puro de uma criança: aquele que é conquistado quando se abraça o brinquedo preferido. E desejamos que neste sorriso esteja representado também o bem-estar da sua família e da comunidade ao redor.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="border-b-4 border-purple">
                    <div className="text-center bg-purple text-white font-bold text-2xl py-2">Galeria de Fotos</div>
                    <div className="container mx-auto overflow-hidden h-96">
                        <Swiper slidesPerView={3}
                                spaceBetween={10}
                                lazy={true}
                                pagination={{
                                    "clickable": true
                                }}
                                navigation={true}
                                className="swiper-lazy-style"
                        >
                            { aboutUs.gallery && aboutUs.gallery.map((image, key) => (
                                <SwiperSlide key={key}>
                                    <Image src={image.thumbnail} className="w-full h-32 border-white border-solid border-4 swiper-lazy" />
                                    <div className="swiper-lazy-preloader swiper-lazy-preloader-purple"></div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
                <div className="bg-purple-custom">
                    <div className="container mx-auto">
                        <div className="flex flex-col-reverse md:flex-row">
                            <div className="flex-1">
                                <img loading="lazy" src={Images.folder} className="md:float-right" alt="" />
                            </div>
                            <div className="flex-1 pt-12 px-12 md:pt-10 lg:px-32">
                                <DownloadPdf sendCatalog/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-6 bg-darkpurple w-full"></div>
            </div>
        </Main>
    )
}

export default SobreNosPage
