/* eslint-disable global-require */

exports.gallery =  [
    {
        thumbnail: "aboutus/gallery1.jpg",
    },
    {
        thumbnail: "aboutus/gallery2.jpg",
    },
    /*{
        thumbnail: "aboutus/gallery3.jpg",
    },*/
    {
        thumbnail: "aboutus/gallery6.jpg",
    },
    {
        thumbnail: "aboutus/gallery4.jpg",
    },
    /*{
        thumbnail: "aboutus/gallery5.jpg",
    },*/
    /*{
        thumbnail: "aboutus/gallery7.jpg",
    },*/
    /*{
        thumbnail: "aboutus/gallery8.jpg",
    },*/
    {
        thumbnail: "aboutus/gallery10.jpg",
    },
    {
        thumbnail: "aboutus/gallery9.jpg",
    },
    /*{
        thumbnail: "aboutus/gallery11.jpg",
    },*/
    {
        thumbnail: "aboutus/gallery12.jpg",
    },
    {
        thumbnail: "aboutus/gallery13.jpg",
    },
    /*{
        thumbnail: "aboutus/gallery14.jpg",
    },
    {
        thumbnail: "aboutus/gallery15.jpg",
    },
    {
        thumbnail: "aboutus/gallery16.jpg",
    },
    {
        thumbnail: "aboutus/gallery17.jpg",
    },*/
    {
        thumbnail: "aboutus/gallery18.jpg",
    },
    {
        thumbnail: "aboutus/gallery19.jpg",
    },
    /*{
        thumbnail: "aboutus/gallery20.jpg",
    },*/
    {
        thumbnail: "aboutus/gallery22.jpg",
    },
    {
        thumbnail: "aboutus/gallery21.jpg",
    }
]